import {CoreConfig} from 'core/types';

export const coreConfig: CoreConfig = {
  app: {
    appName: 'ERP',
    appTitle: 'Boshqaruv paneli',
    appLogoImage: 'assets/images/logo/logo.svg',
    appLanguages: [
      'uz', 'crl', 'ru', 'kk',
    ],
    appLanguage: 'uz',
  },
  layout: {
    skin: 'default',
    type: 'horizontal',
    animation: 'none',
    enableAnimation: true,
    menu: {
      hidden: true,
      collapsed: false,
    },
    navbar: {
      hidden: false,
      type: 'floating-nav',
      background: 'navbar-light',
    },
    footer: {
      hidden: true,
      type: 'footer-static',
      background: 'footer-light',
    },
    enableLocalStorage: true,
    customizer: false,
    scrollTop: false,
    buyNow: false,
    loadingBar: true,
  }
};
